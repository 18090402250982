@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&family=Poppins:wght@400;600&display=swap');

.describe {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 197, 234, 0.05); /* Light background */
  padding: 80px 0;
}

.center-box {
  background-color: #ffffff; /* White box */
  padding: 70px;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow */
  text-align: center;
  max-width: 1000px;
  margin:auto;
}

.highlighted-text {
  font-family: 'Roboto Slab', serif;
  font-size: 4rem;
  font-weight: 700;
  color: #00b4fe; /* Bright blue */
  text-transform: uppercase;
  margin-bottom: 20px;
}

.brand-name {
  font-size: 5rem;
  font-weight: bold;
  color: #00061C; /* Distinct color for "Terafac" */
  line-height: 1.5;
}

.description {
  font-family: 'Poppins', sans-serif;
  font-size: 1.5rem;
  color: #333333; /* Dark text */
  font-weight: 400;
  line-height: 1.6;
  white-space: normal; /* Ensure wrapping */
  overflow: visible; /* Disable clipping */
  
}

@media (max-width: 768px) {
  .describe {
    padding: 60px 0;
  }

}

@media only screen and (max-width: 575px) {
  .center-box {
    padding: 40px; /* Reduced padding for smaller screens */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .highlighted-text {
    font-size: 4rem; /* Adjusted font size for smaller screens */
  }

  .brand-name {
    font-size: 2rem !important; /* Adjusted size for mobile */
    justify-content: center;
   
  }

  .description {
    font-size: 1rem; /* Adjusted font size for better readability */
    line-height: 1.6; /* Maintain readability with proper spacing */
    white-space: normal !important; /* Allow text wrapping */
    overflow: visible !important; /* Ensure all text is shown */
    text-overflow: unset !important; /* Remove ellipsis truncation */
    word-wrap: break-word !important; /* Ensure long words wrap */
    word-break: break-word !important; /* Ensure text breaks correctly */
    display: block; /* Ensure it behaves as a block element */
    max-width: 100%; /* Ensure it does not overflow its parent */
    text-align: center; /* Optional: Align text for a cleaner look */
    justify-content: center;
  }
}

.describe .icon-boxes h5 {
  font-size: 38px;
  font-weight: 700;
  color: #2f327d;
  margin-bottom: 10px;
}

.describe .icon-boxes .question-mark {
  color: #f48c06;
}

.list-group .list {
  font-size: 23px;
  margin: 2px 0;
  list-style: none;
}

.describe .video-box {
  background-size: cover;
  min-height: 430px;
  border-radius: 15px;
  width: 95%;

}

.describe .play-btn {
  width: 94px;
  height: 94px;
  background: radial-gradient(#428bca 50%, rgba(66, 139, 202, 0.4) 52%);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: calc(50% - 47px);
  top: calc(50% - 47px);
  overflow: hidden;
}

.describe .play-btn::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-40%) translateY(-50%);
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 15px solid #fff;
  z-index: 100;
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.describe .play-btn::before {
  content: '';
  position: absolute;
  width: 120px;
  height: 120px;
  animation-delay: 0s;
  animation: pulsate-btn 2s;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid rgba(66, 139, 202, 0.7);
  top: -15%;
  left: -15%;
  background: rgba(198, 16, 0, 0);
}

@keyframes pulsate-btn {
  0% {
    transform: scale(0.6, 0.6);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.video-left-div {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #25B3BC;
  width: 25%;
  height: 30%;
  border-radius: 15px;
}

.video-right-div {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #F3AC50;
  width: 25%;
  height: 45%;
  border-radius: 15px;
}

.learn-more-btn {
  background-color: #0D0D0D;
  align-self: flex-start;
  color: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 20px;
  font-weight: 800;
  margin-top: 20px;
  font-size: 20px;
}

.describe-top-level-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #00b4fe;
  text-align: left;
}

@media only screen and (min-width: 1500px){
  .fs-xl-heading {
    font-size: 35px !important;
  }

  .fs-xl-para {
    font-size: 20px !important;
  }

  @media only screen and (min-width: 1024px) {
  .fs-xl-heading {
    font-size: 35px !important;
  }

  .fs-xl-para {
    font-size: 20px !important;
  }
  .describe{
    background-color:rgba(41, 197, 234, 0.07);
  }
}
}
