/* header css */

.discord-button_two {
  border-radius: 10px;
  background-color: #060606;
  border: 2px solid #29c5ea;
  padding: 8px 25px;
  color: white;
  font-weight: 300;
  margin-top: auto;

}

.discord-button_two:hover {
  border-radius: 10px;
  background-color: #29c5ea;
  padding: 8px 25px;
  color: white;
  font-weight: 300;
}

.navbar_two {
  height: 100px !important;
  /* position: fixed; */
  font-family: Poppins, sans-serif;
  z-index: 1055;
  /* background: transparant; */
  background-color: red;
}

.navbar.colorChange_two {
  background-color: red !important;
  /* background-color: rgba(13, 13, 13, 0) !important;  */

}

.navbar-title {
  font-size: 25px;
  color: white;
  margin-left: 10px !important;
  font-weight: 700;
}

.navbar-brand_two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 13px;
  z-index: 11;
  /* Ensure logo stays visible */
  margin-left: 5px;
}

.navbar-icon-box {
  background-color: black;
  border: 2px solid #29c5ea;
}

.navbar-toggler-icon {
  /* border-color: white;
      color: white; */
  /* background-color: black; */
  /* border: 4px solid #25B3BC; */
}

/* Manually Added  */

/* 
  @media (max-width: 991.98px) {
      .navbar-toggler {
      }
  
      .navbar-collapse {
       float: right;
      }
  
  } */

@media (min-width: 768px) {
  .navbar-brand {
    margin-left: 1px;
  }
}


/* .navbar-collapse {
          background-color: rgba(13, 13, 13, 0.80);
      } */

.nav-bg {
  background-color: transparent;
}

.nav-link {
  padding: 0 !important;
  margin: 5px 0 !important;
}


.navbar-nav {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.discord-button {
  margin: 8px 0;
  justify-content: flex-end;
  margin-right: 1;
}

.nav-alignment {
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  /* Ensures alignment to the right */
  width: 100%;
}

.navbar {
  height: 70px;
  margin: 0 !important;
  /* position: fixed; */
  padding: 0 !important;
}


@media only screen and (max-width: 768px) and (min-width: 1024px) {
  .navbar-collapse {
    /* display: flex;
          align-items: flex-end;
          justify-content: flex-end; */
  }

  .nav-alignment {
    display: flex;
    align-items: flex-start;
  }

}

/* Extra small devices (phones, 0px - 576px) */
@media (max-width: 576px) {

  /* Styles for mobile screens */
  .navbar_height {
    height: 170px;

  }
}

/* Small devices (larger phones, 577px - 768px) */
@media (max-width: 768px) {

  /* Styles for tablets and larger mobiles */
  .navbar_height {
    height: 170px;
  }
}

@media (max-width: 1024px) {
  .navbar_height {
    height: 170px !important;

  }
}

@media (max-width: 1280px) {
  .navbar_height {
    height: 170px !important;

  }
}

@media (max-width: 1440px) {
  .navbar_height {
    height: 210px !important;


  }
}