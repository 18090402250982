/* General Styling */
.demo {
    /* padding: 20px 0; */
}

.video-inner-div {
    position: relative;
    height: 100%;
    /* padding: 20px; */
  }

.video-section {
    padding: 80px 0 50px 0;
    background-color: white !important;
}

.video-sub-heading {
    color: #29c5ea;
}

.list-group {
    font-family: 'Nunito', sans-serif !important;
}

.list-item {
    font-family: 'Nunito', sans-serif !important;
}

.line-height {
    line-height: 1;
}

.icon-boxes {
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-start;
}

/* Headings */
.demo .icon-boxes h5 {
    font-size: 38px;
    font-weight: 700;
    color: #2f327d;
    margin-bottom: 15px;
}

/* List Styling - Using Default Bullets */
.list-group {
    list-style-type: disc; /* Enables default bullets */
    padding-left: 20px; /* Proper indentation */
    padding-top: 20px;
}
.list-item {
    font-size: 20px;
    line-height: 1.5;
    font-family: 'Poppins';
    margin-top: 10px;
}

/* Video Section Styling */
.demo .video-box {
    background-size: cover;
    /* min-height: 430px; */
    border-radius: 15px;
    width: 95%;
}

.demo .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#428bca 50%, rgba(66, 139, 202, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
}

.video-heading-span {
    /* border: 1px solid greenyellow; */
}

/* Responsive Styling */
/* only screen and */
@media (max-width: 575px) {
    .list-group {
        padding-left: 15px; /* Slightly smaller indentation on mobile */
    }

    .list-item {
        font-size: 1rem;
    }

    .video-heading-span {
        font-size: 28px !important;
        margin-top: -10px;
    }

    .video-sub-heading {
        font-size: 19.2px !important;
        margin-top: -10px;
        font-family: "Poppins";
    }

    .video-section {
        /* padding: 40px 20px 0px 20px; */
    }
}

@media (max-width: 768px) {
    .video-section {
        padding: 60px 0 40px 0 !important;
        background-color: white !important;
    }
}


@media (max-width: 991px) {
    .video-section {
        padding: 60px 0 0 0;
        background-color: white !important;
    }

    .icon-boxes {
        padding: 0 20px;
        margin-bottom: 20px;
    }
}


@media (min-width: 575px) and (max-width: 768px) {
    .video-heading-span {
        font-size: 32px !important;
        margin-top: -10px;
    }

    .video-sub-heading {
        font-size: 28px !important;
        margin-top: -5px;
        font-family: "Poppins";
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .video-heading-span {
        font-size: 34px !important;
        margin-top: -15px;
    }

    .video-sub-heading {
        font-size: 24px !important;
        margin-top: -10px;
        font-family: "Poppins";
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .video-heading-span {
        font-size: 38px !important;
        margin-top: -20px;
    }

    .video-sub-heading {
        font-size: 28px;
        margin-top: -20px;
        font-family: "Poppins";
    }
}


/* Screen Sizes between 1024px and 2560px */
@media (min-width: 1024px) and (max-width: 2560px) {

/*    .video-heading-span {
        font-size: 48px !important;
        margin-top: -20px;
    }

    .video-sub-heading {
        font-size: 38px !important;
        margin-top: -20px;
        font-family: "Poppins";
    }*/

    .video-heading-span {
        font-size: 38px !important; /* Font size for medium to large screens */
        margin-top: -20px;
        font-family: 'Poppins';
    }

    .video-sub-heading {
        font-size: 28px !important; /* Adjusted font size */
        margin-top: -20px;
        font-family: "Poppins";
    }
}
