.contact-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.home-contact-space {
    padding-top: 80px;
}

.contact-us-page {
    padding: 0 0 80px 0 !important;
    margin: 0 !important;
    background-color: rgba(41, 197, 234, 0.05);
}

.contact-form {
    background-color: white !important;
}

.php-email-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-main-div {
    width: 100vw;
    /* border: 1px solid red; */
    padding: 80px 0 80px 0 !important;
    margin: 0 !important;
    background-color: rgba(41, 197, 234, 0.05);
}

.lets-connect {
    font-family: 'Qwigley', cursive !important;
    font-size: 10vw;
    font-weight: 400;
    letter-spacing: 0px;
    white-space: normal;
    text-transform: none !important;
    color: white;
}

.contact-input-fields {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    width: 100%;
}

/* Make sure each input container takes full width */
.contact-input-fields .col-12 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media (max-width: 768px) {

    .home-contact-space {
        padding-top: 60px;
    }

    .contact-page {
        height: 60vh;
    }

    .contact-main-div {
        padding: 40px 0 60px 0 !important;
    }

    .contact-us-page {
        padding: 0 0 60px 0 !important;
    }
}

.map-div {
    margin-top: 40px;
}

.home-contact .section-category {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.home-contact .content h2 {
    font-weight: 700;
    line-height: 1.2;
}

.home-contact .content .lead {
    color: color-mix(in srgb, var(--default-color), transparent 25%);
    font-family: 'Nunito', sans-serif;
}

.descriptive-sub-heading {
    /* margin: 0 !important; */
    font-family: 'Poppins', sans-serif !important;
    color: #29c5ea !important;
    font-weight: 400;
}

.home-contact .contact-info .info-item {
    font-size: 1.1rem;
    font-family: 'Nunito', sans-serif;
}

.home-contact .contact-info .info-item i {
    color: var(--accent-color);
    font-size: 1.4rem;
}

.home-contact .contact-info .map-link {
    color: var(--heading-color);
    font-weight: 500;
    font-size: 1.1rem;
    transition: all 0.3s ease;
}

.home-contact .contact-info .map-link i {
    transition: transform 0.3s ease;
}

.home-contact .contact-info .map-link:hover {
    color: var(--accent-color);
}

.home-contact .contact-info .map-link:hover i {
    transform: translateX(5px);
}

.home-contact .contact-form {
    background-color: var(--surface-color);
    border: none;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
}

.main-contact-heading {
    font-size: 45px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #00061c;
}

@media only screen and (max-width: 575px) {

    .main-contact-heading {
        font-size: 35px !important;
        font-weight: 500 !important;
        font-family: 'Poppins', sans-serif;
        color: #00061c;
    }

    .descriptive-sub-heading {
        font-size: 19.2px;
    }
}

@media (min-width: 575px) and (max-width: 768px) {
    .descriptive-sub-heading {
        font-size: 28px !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .descriptive-sub-heading {
        font-size: 24px !important;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .descriptive-sub-heading {
        font-size: 28px !important;
    }
}

@media (min-width: 1024px) and (max-width: 2560px) {
    .descriptive-sub-heading {
        font-size: 28px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 1024px) {
    .main-contact-heading {
        font-size: 35px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        color: #00061c;
    }
}

/* Ensure all form controls have consistent width */
.contact-input-fields .form-control,
.contact-input-fields .btn-submit {
    width: 100%;
    max-width: 100%;
    font-family: 'Poppins', sans-serif;
}

.input-background {
    background-color: rgba(41, 197, 234, 0.001) !important;
}

.home-contact .contact-form .form-control,
.home-contact .contact-form .form-select {
    padding: 0.75rem 1.25rem;
    border-color: #e5e5e5;
    border-radius: 8px;
    font-size: 1rem;
    
}

.home-contact .contact-form .form-control:focus,
.home-contact .contact-form .form-select:focus {
    border-color: color-mix(in srgb, var(--accent-color), transparent 50%);
    box-shadow: 0 0 0 0.25rem color-mix(in srgb, var(--accent-color), transparent 90%);
}

.home-contact .contact-form .form-control::placeholder,
.home-contact .contact-form .form-select::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
}

.home-contact .contact-form .btn-submit {
    padding: 0.75rem 2rem;
    transition: all 0.3s ease;
    border-radius: 10px;
    background-color: #3b3b3b;
    border: 2px solid #29c5ea;
    color: white;
    font-weight: 300;
}

.home-contact .contact-form .btn-submit:hover {
    background-color: #29c5ea;
}