
.whoweare {
  padding: 0px 0 90px 0 !important;
}

.question-mark {
  color: #f48c06;
}

.whoweare-section-title h2 {
  font-size: 45px;
  font-weight: 600;
  color: #2f327d;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}

.whoweare-section-title p {
  font-size: 25px;
  font-family: 'Nunito', sans-serif;
}

.mobile-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2f327d;
  text-align: center;
}

.experience-job-heading {
  font-size: 45px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #2f327d;
  text-align: center;
  margin-bottom: 20px !important;
}

/* Features Box Style */
.company-features {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
}

.company-feature {
  width: 350px;
  max-width: 100%;
  margin: 0;
}

.whoweare .feature-icon-box {
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  background: rgba(41, 197, 234, 0.07);
  height: 100% !important;
  padding: 20px 15px;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.whoweare .feature-icon-box .feature-title-box h4 {
  font-weight: 600;
  font-size: 22px;
  color: #00061c;
  font-family: 'Poppins', sans-serif;
  min-height: 50px;
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whoweare .feature-icon-box p {
  line-height: 1.5;
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  margin: 1rem 0 0 0 !important;
  padding: 0 !important;
  min-height: 80px;
  display: flex;
}

.feature-title-box {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.img-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.whoweare-icon {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.enter-jex-button {
  display: inline-block;
  padding: 10px 20px !important;
  background-color: #0d0d0dcc;
  color: #fff;
  text-decoration: none;
  border-radius: 15px;
  border: 4px solid #25b3bc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 200px;
  width: 100%;
  margin-top: 15px;
  text-align: center;
}

.enter-jex-button:hover {
  background-color: #25b3bc;
  color: #fff !important;
}

/* Responsive Styles */

/* Large Desktop (1440px and above) */
@media (min-width: 1440px) {
  .company-features {
    gap: 2.5rem;
  }
  
  .company-feature {
    flex: 0 1 350px;
  }
  
  .whoweare .feature-icon-box p {
    font-size: 20px;
  }
  
  .whoweare-icon {
    height: 60px;
    width: 60px;
  }
}

/* Desktop (1024px to 1439px) */
@media (min-width: 1024px) and (max-width: 1439px) {
  .company-features {
    gap: 2rem;
  }
  
  .company-feature {
    flex: 0 1 300px;
  }
  
  .whoweare .feature-icon-box .feature-title-box h4 {
    font-size: 20px;
  }
  
  .whoweare .feature-icon-box p {
    font-size: 16px;
  }
}

/* Tablet (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .whoweare {
    margin-top: 40px;
  }
  
  .company-features {
    padding-top: 30px;
    gap: 1.5rem;
  }
  
  .company-feature {
    flex: 0 1 280px;
  }
  
  .whoweare .feature-icon-box {
    padding: 20px 15px;
  }
  
  .whoweare .feature-icon-box .feature-title-box h4 {
    font-size: 20px;
    min-height: 60px;
  }
  
  .whoweare .feature-icon-box p {
    font-size: 16px;
    line-height: 1.4;
  }
  
  .whoweare-icon {
    height: 40px;
    width: 40px;
  }
}

/* Small Tablet / Large Mobile (481px to 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .whoweare {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  
  .company-features {
    flex-direction: column;
    padding-top: 20px;
    gap: 1.5rem;
  }
  
  .company-feature {
    width: 100%;
    max-width: 450px;
  }
  
  .whoweare .feature-icon-box {
    padding: 25px 15px;
  }
  
  .whoweare .feature-icon-box .feature-title-box h4 {
    font-size: 20px;
    min-height: auto; 
    margin-bottom: 10px;
  }
  
  .whoweare .feature-icon-box p {
    font-size: 16px;
    min-height: auto; 
    text-align: center;
  }
  
  .whoweare-icon {
    height: 40px;
    width: 40px;
  }
}

/* Mobile (320px to 480px) */
@media (max-width: 480px) {
  .whoweare {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  
  .company-features {
    flex-direction: column;
    padding-top: 15px;
    gap: 1.25rem;
  }
  
  .company-feature {
    width: 100%;
    max-width: 100%;
    padding: 0 10px;
  }
  
  .whoweare .feature-icon-box {
    padding: 20px 15px;
    height: auto;
  }
  
  .whoweare .feature-icon-box .feature-title-box h4 {
    font-size: 18px;
    min-height: auto; 
    margin-bottom: 8px;
    margin-top: 8px; 
  }
  
  .whoweare .feature-icon-box p {
    font-size: 16px;
    line-height: 1.4;
    min-height: auto;
    margin-top: 8px !important; 
  }
  
  .whoweare-icon {
    height: 35px;
    width: 35px;
  }
  
  .enter-jex-button {
    max-width: 80%;
  }
}

/* Additional optimization for very small screens */
@media (max-width: 320px) {

  .whoweare {
    padding-top: 30px;
    padding-bottom: 60px;
  }

  .whoweare .feature-icon-box {
    padding: 15px 10px;
  }
  
  .whoweare .feature-icon-box .feature-title-box h4 {
    font-size: 16px;
  }
  
  .whoweare .feature-icon-box p {
    font-size: 14px;
  }
  
  .whoweare-icon {
    height: 30px;
    width: 30px;
  }
}