#hero {
    width: 100%;
    height: 100vh;
    background-size: cover;
    position: relative;
    padding: 0;
}

#hero:before {
    content: "";
    background: rgba(0, 6, 18, 0.9);
    position: absolute;
    bottom: 0;
}

#hero .hero-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

#hero h1 {
    margin: 0 0 20px 0;
    font-size: 58px;
    font-weight: 700;
    line-height: 70px;

    /* text-transform: uppercase; */
    color: #fff;
    /* line-height: 80px; */

}

.line-height {
    line-height: 1.5;
}

#hero h2 {
    color: #eee;
    margin-bottom: 50px;
    font-size: 40px;
    margin-top: 20px;
    /* line-height: 70px; */
}

#hero .btn-get-started {
    font-family: "Open Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    padding: 10px 35px 10px 35px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: #ff4a17;
    border: 2px solid #ff4a17;
}

#hero .btn-get-started:hover {
    border-color: #fff;
    background: rgba(255, 255, 255, 0.1);
}

#hero .btn-watch-video {
    font-size: 16px;
    display: inline-block;
    transition: 0.5s;
    margin-left: 25px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

#hero .btn-watch-video i {
    line-height: 0;
    color: #fff;
    font-size: 32px;
    transition: 0.3s;
    margin-right: 8px;
}

#hero .btn-watch-video:hover i {
    color: #ff4a17;
}


/* Small mobile screen */
@media only screen and (max-width: 320px) {
    #hero {
        width: 95%;
        height: 70vh;
        background-size: cover;
        position: relative;
        padding: 0;
        margin: auto;
    }

    #hero:before {
        content: "";
        background: rgba(13, 20, 26, 0.7);
        position: absolute;
        bottom: 0;
    }

    #hero .hero-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    #hero h1 {
        margin: 0 0 20px 0;
        font-size: 28px;
        font-weight: 700;
        line-height: 35px;
        /* text-transform: uppercase; */
        color: #fff;

    }

    #hero h2 {
        color: #eee;
        line-height: 30px;
        font-size: 18px;
    }

    #hero .btn-get-started {
        font-family: "Open Sans", sans-serif;

        font-weight: 500;
        font-size: 10px;
        display: inline-block;
        padding: 5px 15px 5px 15px;
        border-radius: 4px;
        transition: 0.5s;
        color: #fff;
        background: #ff4a17;
        border: 2px solid #ff4a17;
    }


}


/* Medium mobile screen */
@media only screen and (min-width: 321px) and (max-width: 375px) {

    #hero {
        width: 95%;
        height: 70vh;
        background-size: cover;
        position: relative;
        padding: 0;
        margin: auto;
    }

    #hero:before {
        content: "";
        background: rgba(13, 20, 26, 0.7);
        position: absolute;
        bottom: 0;
    }

    #hero .hero-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    #hero h1 {
        margin: 0 0 20px 0;
        font-size: 32px;
        font-weight: 700;
        line-height: 35px;
        /* text-transform: uppercase; */
        color: #fff;

    }

    #hero h2 {
        color: #eee;
        line-height: 30px;
        font-size: 24px;
    }


}


/* Large Mobile */
@media only screen and (min-width: 376px) and (max-width: 767px) {
    #hero {
        width: 95%;
        height: 70vh;
        background-size: cover;
        position: relative;
        padding: 0;
        margin: auto;
    }

    #hero:before {
        content: "";
        background: rgba(13, 20, 26, 0.7);
        position: absolute;
        bottom: 0;
    }

    #hero .hero-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    #hero h1 {
        margin: 0 0 20px 0;
        font-size: 34px;
        font-weight: 700;
        line-height: 35px;
        text-transform: uppercase;
        color: #fff;

    }

    #hero h2 {
        color: #eee;
        line-height: 30px;
        font-size: 26px;
    }


}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

    #hero {
        width: 95%;
        height: 70vh;
        background-size: cover;
        position: relative;
        padding: 0;
        margin: auto;
    }

    #hero:before {
        content: "";
        background: rgba(13, 20, 26, 0.7);
        position: absolute;
        bottom: 0;
    }

    #hero .hero-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }

    #hero h1 {
        margin: 0 0 20px 0;
        font-size: 34px;
        font-weight: 700;
        line-height: 35px;
        /* text-transform: uppercase; */
        color: #fff;

    }

    #hero h2 {
        color: #eee;
        line-height: 30px;
        font-size: 26px;
    }


}

/* Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {

    #hero {
        width: 100%;
        height: 90vh;
        background-size: cover;
        position: relative;
        padding: 0;
        /* border: 4px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;

    }

    #hero:before {
        content: "";
        background: rgba(13, 20, 26, 0.7);
        position: absolute;
        bottom: 0;
    }

    #hero .hero-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        /* border: 4px solid green; */

    }

}

/* Large Laptop */
@media only screen and (min-width: 1440px) {

    #hero {
        width: 100%;
        height: 90vh;
        background-size: cover;
        position: relative;
        padding: 0;
        /* border: 4px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;

    }

    #hero:before {
        content: "";
        background: rgba(13, 20, 26, 0.7);
        position: absolute;
        bottom: 0;
    }

    #hero .hero-container {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        /* border: 4px solid green; */

    }
}