#footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
    /* margin-top: 6vw !important; */
    padding-left: 4vw !important;
    padding-right: 4vw !important;
}


.popup-message { 
    /* border: 1px solid greenyellow !important; */
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column;
    /* padding: 15px */
    width: 100%;
  }

#footer .footer-top {
    background: rgba(0,6,18,0.9);
    border-bottom: 1px solid #222222;
    padding: 50px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 32px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Audiowide';
}

#footer .footer-top .footer-info h3 span {
    color: rgba(0,6,18,0.9);
}

#footer .footer-top .footer-info p {
    font-size: 15px;
    line-height: 26px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#footer .footer-top .social-links {
    display: flex;
    align-items: center;
    flex-direction: row;
}

#footer .footer-top .social-links a {
    font-size: 25px;
    display: inline-block;
    background: rgba(12, 12, 12, 0.9);
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

#footer .footer-top .social-links a:hover {
    background:#00B4FE;
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

.footer-link {
    font-size: 15px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #00B4FE;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #00B4FE;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4px;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
    outline: none !important;
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #29c5ea;
    color: white;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

#footer .footer-top .footer-newsletter form input[type=email]:hover {
    outline: none !important;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}

a {
    text-decoration: none;
}

@media (max-width: 575px) {
    #footer .footer-top .footer-info p {
        font-size: 12px;
        line-height: 20px;
    }

    .footer-link {
        font-size: 12px;
    }

    #footer .footer-top .footer-links ul li {
        padding: 5px 0;
        display: flex;
        align-items: center;
    }

     #footer .footer-newsletter p {
        line-height: 12px;
    }  

    #footer .copyright {
        font-size: 10px;
        text-align: center;
        padding-top: 20px;
    }
}

@media (max-width: 768px) {
    #footer .footer-top {
        padding: 40px 0 30px 0;
    }
}

@media (max-width: 2559px) {
    #footer .footer-newsletter p {
        line-height: 16px;
    }    
}

@media (min-width: 2560px) {

    #footer .footer-newsletter p {
        line-height: 45px;
    }   

    #footer .footer-top .footer-info h3 {
        font-size: 75px;
        margin: 0 0 25px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 800;
    }

    #footer .footer-top .footer-info p {
        font-size: 35px;
        line-height: 54px;
        margin-bottom: 0;
        font-family: "Raleway", sans-serif;
        color: #fff;
    }

    .footer-link {
        font-size: 35px;
        line-height: 54px;
        margin-bottom: 0;
        font-family: "Raleway", sans-serif;
        color: #fff;
    }

    .footer-links h4 {
        font-size: 45px !important;
    }

    .footer-newsletter h4 {
        font-size: 45px !important;
    }

    .footer-newsletter p {
        font-size: 35px;
    }

    #footer .footer-top .social-links a {
        font-size: 43px;
        line-height: 1;
        padding: 10px 0;
        margin-right: 25px;
        width: 66px;
        height: 66px;
    }

    #footer .footer-top .footer-newsletter form {
        margin-top: 30px;
        background: #fff;
        padding: 10px 14px;
        position: relative;
        border-radius: 4px;
        width: 80%;
    }

    #footer .copyright {
        font-size: 25px;
    }

    #footer .footer-top .footer-newsletter form input[type=submit] {
        font-size: 21px;
    }

    #footer .footer-top .footer-newsletter form input[type=email] {
        font-size: 21px;
    }

    #footer .footer-top .footer-links ul li {
        padding: 15px 0;
    }
}